<template>
  <div>
    <ModalEditSpaceChannels
      v-if="showEditSpaceChannels"
      :showModal="showEditSpaceChannels"
      :meetingtypeId="meetingtypeId"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditSpaceChannels"
    >
      <template v-slot:title>Channel publication</template>
    </ui-section-header>

    <table class="table is-fullwidth is-narrow">
      <thead>
        <tr>
          <th>Active</th>
          <th>Channel</th>
          <th>Public</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width="50">
            <span class="icon">
              <font-awesome-icon
                v-if="!spaceOnChannel"
                :icon="['fas', spaceOnChannel ? 'check' : 'times']"
                :class="spaceOnChannel ? 'has-text-success' : 'has-text-danger'"
              />
            </span>
          </td>
          <td>
            <span>{{ channel.Name }}</span>
          </td>
          <td>
            <span class="icon">
              <font-awesome-icon
                :icon="['fas', spaceActiveOnChannel ? 'check' : 'times']"
                :class="
                  spaceActiveOnChannel ? 'has-text-success' : 'has-text-danger'
                "
              />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const ModalEditSpaceChannels = () =>
  import('@/components/Spaces/ModalEditSpaceChannels')

export default {
  components: {
    ModalEditSpaceChannels,
  },

  props: {
    meetingtypeId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      channelId: Number(this.$route.params.channelId),
      locationId: Number(this.$route.params.locationId),
      showEditSpaceChannels: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('spaceStore', ['space']),

    channels() {
      let channels = []
      if (this.locationState) {
        channels = this.locationState.Channels.filter(
          (c) => c.ChannelId === this.channelId
        )
      }
      return channels
    },

    spaceOnChannel() {
      let result = false

      if (this.channel && this.space && this.meetingtypeId) {
        let channelIndex = this.space.Channels.findIndex(
          (c) =>
            c.MeetingtypeId === this.meetingtypeId &&
            c.ChannelId === this.channel.Id
        )
        if (channelIndex > -1) {
          result = true
        }
      }

      return result
    },

    spaceActiveOnChannel() {
      let result = false

      if (this.channel && this.space && this.meetingtypeId) {
        let channelIndex = this.space.Channels.findIndex(
          (c) =>
            c.MeetingtypeId === this.meetingtypeId &&
            c.ChannelId === this.channel.Id
        )
        if (channelIndex > -1) {
          result = this.space.Channels[channelIndex].IsPublic
        }
      }

      return result
    },
  },

  created() {},

  methods: {
    checkSpaceOnChannel(channelId) {
      let result = false

      let channelIndex = this.space.Channels.findIndex(
        (c) =>
          c.MeetingtypeId === this.meetingtypeId && c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = true
      }

      return result
    },

    checkSpaceActiveOnChannel(channelId) {
      let result = false

      let channelIndex = this.space.Channels.findIndex(
        (c) =>
          c.MeetingtypeId === this.meetingtypeId && c.ChannelId === channelId
      )
      if (channelIndex > -1) {
        result = this.space.Channels[channelIndex].IsPublic
      }

      return result
    },

    setShowEditSpaceChannels() {
      this.showEditSpaceChannels = true
    },

    hideModal() {
      this.showEditSpaceChannels = false
    },
  },
}
</script>
